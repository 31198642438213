<template>
  <form @submit.prevent="submit">
    <component :is="final.widget" :errors="errors" :element="final" :value="value" @input="(e) => $emit('input', e)"/>
    <slot>
      <button type="submit" class="button settingButton is-primary mt-3" :class="{'is-loading': loading}">Submit</button>
    </slot>
  </form>
</template>
<script>
import SchemaInput from "./Input";
import SchemaSelect from "./Select";
import SchemaCheckbox from "./Checkbox";
import SchemaObject from "./Object";
import formatSchema from "./utils";
import clone from "lodash-es/cloneDeep";

export default {
  name: "SchemaForm",
  props: {
    value: {
      type: [Object, String, Number, Array, Boolean],
      default: () => ({})
    },
    schema: {
      type: Object,
      required: true,
    },
    uiSchema: {
      type: Object,
      default: () => ({})
    },
    loading: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Function,
      default: function(e) {
        this.$emit('submit', this.value)
      },
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      final: {}
    }
  },
  mounted() {
    this.final = formatSchema(clone(this.schema), clone(this.uiSchema));
  },
  components: {
    SchemaInput,
    SchemaSelect,
    SchemaCheckbox,
    SchemaObject
  }
}
</script>

<style lang="scss" scoped>
  .settingButton {
    // mobile and tablet only
    @media (max-width: 1023px) {
      min-width: 100%;
    }

  }
</style>
