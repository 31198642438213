<template>
  <div class="field">
    <div class="control">
      <label class="checkbox">
        {{ element.props.title || element.title }}
        <input type="checkbox" :checked="value" @change="e => $emit('input', e.target.checked)">
      </label>
    </div>
    <p class="help is-danger" v-if="isError">{{ errorMessage }}</p>
  </div>
</template>
<script>
import Input from "./Input";
export default {
  name: "Checkbox",
  extends: Input,
}
</script>
