<template>
  <div class="field">
    <label class="label">{{ element.props.title || element.title }}</label>
    <div class="control">
      <input
        :type="element.props.type || 'text'"
        class="input"
        :required="element.props.required || false"
        :disabled="element.props.disabled || false"
        :class="{'is-danger': isError}"
        :placeholder="element.examples || ''"
        :name="name"
        :value="value"
        @change="(e) => $emit('input', e.target.value || undefined)"
      />
    </div>
    <p class="help is-danger" v-if="isError">{{ errorMessage }}</p>
  </div>
</template>
<script>
import BaseWidget from "./Base";
export default {
  name: "Input",
  extends: BaseWidget
}
</script>
