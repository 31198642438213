<template>
  <div class="field">
    <form
      class="card-content"
    >
      <template v-for="key in addressFields">
        <div class="field" :key="key">
          <label class="label">{{ $t("address." + key) }}</label>
          <div class="control">
            <input
              type="text"
              class="input"
              :class="{'is-danger': Object.keys(errors).includes(key)}"
              v-model="address[key]"
              :placeholder="key"
            />
          </div>
          <ul v-if="Object.keys(errors).includes(key)" class="help is-danger">
            <li v-for="error in errors[key]" :key="key + error">
              {{ $t(error, [$t("address." + key)])}}
            </li>
          </ul>
        </div>
      </template>
      <div class="field">
        <label class="label">{{ $t("address.country") }}</label>
        <div class="select">
          <select v-model="address.country_id">
            <option value="NL" :selected="value.country_id === 'NL'">{{
              countryList.getName("NL", $store.state.context)
            }}</option>
            <option
              v-for="(country, code) in countries"
              :key="code"
              :value="code"
              :selected="code === value.country_id"
            >{{ country }}</option
            >
          </select>
        </div>
        <ul v-if="Object.keys(errors).includes('country_id')" class="help is-danger">
          <li v-for="error in errors['country_id']" :key="'country_id' + error">
            {{ $t(error, [$t("address.country")])}}
          </li>
        </ul>
      </div>
      <div class="jc-sb">
        <button type="submit" @click="saveAddress" class="button is-primary" :disabled="loading" v-if="nummer == 999">Submit</button>
        <button
          @click.prevent="updateAddress"
          class="button bg-cyan c-white is-borderless"
          :class="{'is-loading': loading}"
          :disabled="loading"
          v-if="nummer !== 999"
        >
          {{ $t('ui.save_close') }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import countryList from "i18n-iso-countries";
import nl from "i18n-iso-countries/langs/nl.json";
import en from "i18n-iso-countries/langs/en.json";
import fr from "i18n-iso-countries/langs/fr.json";
import de from "i18n-iso-countries/langs/de.json";
countryList.registerLocale(nl);
countryList.registerLocale(en);
countryList.registerLocale(fr);
countryList.registerLocale(de);

export default {
  props: {
    value: [Object, Boolean],
    nummer: [Number,String,Boolean]
  },
  data() {
    return {
      address: {
        id: this.value.hasOwnProperty("id") ? this.value.id : "",
        name: this.value.hasOwnProperty("name") ? this.value.name : "",
        dept: this.value.hasOwnProperty("dept") ? this.value.dept : "",
        attn: this.value.hasOwnProperty("tav") ? this.value.tav : "",
        phone: this.value.hasOwnProperty("phone") ? this.value.phone : "",
        email: this.value.hasOwnProperty("email") ? this.value.email : "",
        street: this.value.hasOwnProperty("street") ? this.value.street : "",
        zip: this.value.hasOwnProperty("zip") ? this.value.zip : "",
        city: this.value.hasOwnProperty("city") ? this.value.city : "",
        country_id: this.value.hasOwnProperty("country_id")
          ? this.value.country_id
          : null
      },
      errors: [],
      loading: false,
      countryList,
      save: false,
      countries: {},
      selectedValue: this.value.hasOwnProperty("key") ? this.value.key : this.nummer
    };
  },
  filters: {
    formatted(address) {
      return address
        ? `${address.name} - ${address.street}, ${address.city}`
        : "Standaard Aflever adres";
    }
  },
  mounted() {
    this.countries = this.countryList.getNames(this.$store.state.context);
  },
  methods: {
    change(val) {
      if (val !== 999) {
        // Create copy and emit
        if (!val) {
          this.$emit("input", false);
          return;
        }

        const address = this.addresses[val];
        address.key = val;
        this.$emit("input", address);
      }
    },
    async saveAddress() {
      this.loading = true;
      await this.$http.post('/addresses', this.address)
          .then(response =>{
            this.$emit('saveAddress',response.data.address);
            this.$emit("close");
      })
      .catch(e => {
        this.$toasted.error('Adres niet toegevoegd');
      });
      this.loading = false;
    },
    async updateAddress() {
      this.loading = true;
        await this.$http.put('/addresses', this.address)
            .then(response =>{
              this.$emit('updateAddress',response.data);
              this.$emit("close");
      })
      .catch(e => {
        this.$toasted.error('Adres niet toegevoegd');
      });
      this.loading = false;
  
    },
  },
  computed: {
    addresses() {
      return this.$store.state.addresses.filter(
        address => address.type === "delivery"
      );
    },
    addressFields() {
      return Object.keys(this.address).filter(key => key !== "country_id" && key !== "id");
    },
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
// modal fit mobile and tablet
@media (max-width: 1023px) {
  .modal-card {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }
  .modal-card-head {
    padding: 0.5rem;
  }
  .modal-card-body {
    padding: 0.5rem;
  }
  .modal-card-foot {
    padding: 0.5rem;
  }
  .modal-card-title {
    font-size: 1.5rem;
  }
  .modal-card-foot {
    justify-content: space-between;
  }
  .modal-card-foot .button {
    width: 48%;
  }
}
// button fit screen size
@media (max-width: 1023px) {
  .button {
    width: 100%;
  }
}

</style>
