<script>
export default {
  name: "BaseWidget",
  props: {
    name: {
      type: String,
      default: "input"
    },
    element: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    value: {
      required: false,
    }
  },
  computed: {
    errorMessages() {
      if (this.isError) {
        return this.errors[this.name];
      }

      return [];
    },
    isError() {
      return !!this.errors.hasOwnProperty(this.name);
    }
  }
}
</script>
