<template> 
  <div class="column is-8-widescreen">
    <h1 class="title">{{$t('settings.address_title')}}</h1>
    <hr />
    <div class="columns is-multiline">
      <div class="column is-6-fullhd is-12-widescreen is-12-tablet" v-for="(address, key) in adrresses" :key="key">
        <div
          :class="[address.type == 'default' ? 'box textile-product-selected  ': 'box textile-product ',]"
          @click="selectAdrress(key)"
        >
          <div class="addressesGrid">
            <div class="address-header">
              <p><b>Naam:</b></p>
              <p><b>Straat:</b></p>
              <p><b>ZIP:</b></p>
              <p><b>Land:</b></p>
              <p><b>Email:</b></p>
              <p><b>Telefoon:</b></p>
              <p><b>Type:</b></p>
            </div>
            <div class="address-text">
              <p><span v-if="address.name">{{ address.name }}</span><span v-else>-</span></p>
              <p><span v-if="address.street">{{ address.street }}</span><span v-else>-</span></p>
              <p><span v-if="address.zip">{{ address.zip }}</span><span v-else>-</span></p>
              <p><span v-if="address.country_id" >{{ address.country_id }}</span><span v-else>-</span></p>
              <p><span v-if="address.email">{{ address.email }}</span><span v-else>-</span></p>
              <p><span v-if="address.phone">{{ address.phone }}</span><span v-else>-</span></p>
              <p><span v-if="address.type == 'default'">Standaard adres</span><span v-else>Adres</span></p>
            </div>
          </div>
          <button class="button is-primary standardAdress" @click="setStandardAddress(address)" :disabled="address.type == 'default'">{{$t('settings.address_standard')}}</button>
          <div class="adressButtons">
            <button class="button " @click="changeAddress(address,key)"><svg-icon icon="pencil" class="svg"/></button>
            <button class="button" @click="removeAddress(address)"><svg-icon icon="trash" class="svg"/></button>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div
          class="box textile-product"
          @click="changeAddress(false,999)"
        >
          <p class="" style="text-align: center; font-size: 2rem;  min-height: 15rem; padding: 6rem; ">+</p>
        </div>
      </div>
    </div>
    <modal v-if="partners" v-model="partners" title="Adres gegevens">
      <!-- create address component, with property address.
       Only update the property when something different than original address is selected. -->
      <address-modal v-model="address" @close="partners = false" @saveAddress="saveAddress" @updateAddress="updateAddress" :nummer="nummer" />
    </modal>
  </div>
</template>
<script>
import Modal from "../Modal";
import AddressModal from "./AddressModal";

export default {
  data() {
    return {
      adrresses: { ...this.$store.state.addresses },
      partners: false,
      selectedAddress: "",
      address: "",
      nummer: false
    };
  },
  methods: {
    updateAddress(e){
      this.adrresses = e;
      this.$store.dispatch('updateAddresses',e);
      this.$toasted.success('Adres aangepast');
    },
    saveAddress(e){
      this.adrresses = e;
      this.$store.dispatch('updateAddresses',e);
      this.$toasted.success('Adres toegevoegd');
    },
    changeAddress(address,key) {
      this.partners = !this.partners
      this.address = address;
      // addresses start with standard adress
      this.nummer = parseInt(key);
    },
    selectAdrress(address){
      this.selectedAddress = address;
    },
    async removeAddress(address){
      await this.$http.delete('/addresses/' + address.id, address)
      .then(response =>{
        this.$toasted.success('Adres verwijderd');
        this.adrresses = response.data;
        this.$store.dispatch('updateAddresses',response.data);

      })
      .catch(e => {
        this.$toasted.success('Adres niet verwijderd');
      });
    },
    async setStandardAddress(address){
      await this.$http.put('/standardAddress/' + address.id, this.adrresses)
      .then(response =>{
        this.adrresses = response.data;
        this.$store.dispatch('updateAddresses',response.data);
                this.$toasted.success('Standaard adres gewijzigd');
      })
      .catch(e => {
        this.$toasted.success('Adres niet gewijzigd');
      });
    }
  },
  components: {
    Modal,
    AddressModal
  },
};
</script>

<style lang="scss">
.textile-product {
  border: solid;
  border-color: rgba(215, 228, 224, 0.678);
  border-width: 2px;
  min-width: 10rem;
  min-block-size: 100%;
  &:hover {
    border: solid;
    border-width: 2px;
  }
}
.textile-product-selected {
  border: solid;
  border-color: green;
  border-width: 4px;
  min-width: 10rem;
  min-block-size: 100%;
}

.addressesGrid{
  display: flex;
  .address-header{
    text-align: left;
    display: inline-block;
  }
  
  .address-text{
    display: inline-block;
    text-align: right;
    width: 100%;
  }
}

.standardAdress{
  width: 100%;
}

.adressButtons{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
    .button{
      width: 100%;
    }

}

.svg{
  width: 1.2rem;
}

</style>
