<template>
  <div class="container is-fluid" >
    <div class="columns">
      <div class="column is-4-widescreen">
        <schema-form
          :schema="schema"
          :uiSchema="uiSchema"
          :submit="update"
          v-model="user"
          :errors="errors"
          :loading="loading"
        />
      </div>
      <address-setting/>
    </div>
  </div>
</template>
<script>
import clone from "lodash-es/cloneDeep";
import userSchema from "../schemas/user.schema.json";
import { validate, transformError } from "../utilities/validators.js";
import { LOGIN_USER } from "../store/mutation-types";
import SchemaForm from "../components/SchemaForm";
import AddressSetting from "../components/AddressSetting";

export default {
  data() {
    return {
      schema: userSchema,
      user: {...clone(this.$store.state.user)},
      loading: false,
      errors: {},
      uiSchema: {
        options: {
          title: this.$t('settings.user')
        },
        email: {
          options: {
            disabled: true,
            type: "email",
            title: this.$t('settings.email')
          }
        },
        name: {
          options: {
            title: this.$t('settings.name')
          }
        },
        phone: {
          options: {
            type: 'tel',
            title: this.$t('settings.phone')
          }
        },
        lang: {
          options: {
            title: this.$t('settings.lang')
          }
        },
        settings: {
          options: {
            title: this.$t('settings.settings')
          },
          approve_pdf: {
            options: {
              title: this.$t('settings.approve_pdf')
            }
          },
          pickup_default: {
            options: {
              title: this.$t('settings.pickup_default')
            }
          },
          zoom_text: {
            options: {
              title: this.$t('settings.zoom_text')
            }
          }
        }
      }
    }
  },
  methods: {
    async update(e) {
      this.errors = {};
      try {
        this.loading = true;
        validate(this.schema, this.user);

        const [{ data: { user }}, { data: { settings } }] = await Promise.all([this.$http.put('/user', this.user), this.$http.put('/user/settings', this.user.settings)]);
        this.$store.commit(LOGIN_USER, { ...user, ...{ settings } });
        this.loading = false;
      } catch (errors) {
        console.log(errors);
        const transformed = transformError(errors);
        this.errors = transformed;
      }
      this.loading = false;
    }
  },
  components: {
    SchemaForm,
    AddressSetting,
  }
}
</script>

<style lang="scss">
.horizontal-line {
  height: 2rem;
}

// mobile and tablet swap
</style>
