import clone from "lodash-es/cloneDeep";
import Input from "./Input.vue";
import Select from "./Select.vue";
import Checkbox from "./Checkbox.vue";
import ObjectWidget from "./Object.vue";

const widgetMap = {
  string: {
    text: Input,
    select: Select,
  },
  boolean: {
    checkboxes: Checkbox
  },
  object: {
    field: ObjectWidget
  }
};

const getDefaultWidget = element => {
  const isSelect =
    (element.type === "string" && element.enum) ||
    (element.type === "string" && Array.isArray(element.oneOf));
  if (isSelect) return widgetMap[element.type].select;

  return widgetMap[element.type][Object.keys(widgetMap[element.type])[0]];
}

const getWidget = (uiElement, element) => {
  return uiElement !== undefined
    ? uiElement.widget || getDefaultWidget(element)
    : getDefaultWidget(element);
}

const getOptions = element => {
  return element !== undefined ? element.options || {} : {};
};

const configureObject = (object, uiSchema) => {
  object.widget = getWidget(uiSchema, object);
  object.props = getOptions(uiSchema);
  if (object.type === 'object') {
    for (const element in object.properties) {
      object.properties[element] = configureObject(object.properties[element], uiSchema[element] || {});
    }
  }
  return object;
}

export default function formatSchema(schema, uiSchema) {
  const final = clone(schema);

  return configureObject(final, uiSchema);
}