<template>
  <div class="field">
    <label class="label">{{ element.props.title || element.title }}</label>
    <div class="control">
      <div class="select selectDropdown" :class="{'is-danger': isError}">
        <select class="dropdown selectDropdown" :value="value" @change="(e) => $emit('input', e.target.value || undefined)">
          <option></option>
          <option
            v-for="object in optionsList"
            :value="object.const"
            :key="object.const"
          >
            {{ object.title }}
          </option>
        </select>
      </div>
    </div>
    <p class="help is-danger" v-if="isError"><template v-for="message in errorMessages">{{ message }}</template></p>
    <div class="horizontal-line"></div>
  </div>
</template>
<script>
import Input from "./Input";
export default {
  name: "Select",
  computed: {
    optionsList() {
      // make a list for checkign enum and enumNames
      if (this.element.enum) {
        return this.element.enum.map(value => ({value, title: value}));
      }

      if (this.element.oneOf) {
        return this.element.oneOf;
      }

      return [];
    }
  },
  extends: Input
}
</script>

<style lang="scss">
  .selectDropdown {
    // mobile and tablet only
    @media (max-width: 1023px) {
      width: 100%;
    }
  }
</style>
