<template>
  <div>
    <h1 class="title">{{ element.props.title || element.title }}</h1>
    <hr>
    <template v-for="(child, key) in element.properties">
      <component
        :is="child.widget"
        :key="key"
        :element="child"
        :name="key"
        :errors="errors"
        v-model="value[key]"
      />
    </template>
  </div>
</template>
<script>
import BaseWidget from "./Base";
import SchemaInput from "./Input";
import SchemaSelect from "./Select";
import SchemaCheckbox from "./Checkbox";
import SchemaObject from "./Object";
export default {
  name: "ObjectWidget",
  props: {
    uiSchema: {
      type: Object,
      default: () => ({})
    },
  },
  extends: BaseWidget,
  methods: {
    getError(property) {
      const error = this.errors ? this.errors.find(error => error.dataPath === `.${property}`) : false;
      return error ? error.message : error;
    },
    getValue(key) {
      return this.value && this.value.hasOwnProperty(key) ? this.value[key] : undefined;
    }
  }
}
</script>
